<template>
  <GlPageWrap
    hide-title-on-mobile
    title="Scoring"
  >
    <template #actions>
      <GlCoinSelect class="m-fullwidth" />
    </template>
    <div class="scoring flex fullwidth t-column">
      <TypesScoring class="mr-4 t-mr-0 t-mb-3" />
      <TagsScoring />
    </div>
  </GlPageWrap>
</template>

<script>
// Components
import GlCoinSelect from "@/components/gl-coin-select";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import TagsScoring from "@/pages/scoring/components/TagsScoring";
import TypesScoring from "@/pages/scoring/components/TypesScoring";
// Vuex
import { mapActions } from 'vuex'
// Utils
import { formatDate } from "@/utils/format-date";
import { toComaSeparate } from "@/utils/formatNumber";

export default {
  components: {
    GlPageWrap,
    TagsScoring,
    GlCoinSelect,
    TypesScoring,
  },
  data() {
    return {

    }
  },
  created() {
    this.getTags({})
  },
  methods: {
    formatDate,
    toComaSeparate,
    ...mapActions({
      getTags: 'tagging/getTags',
    }),
  }
}
</script>

<style>
.stat-select .vs__dropdown-toggle {
  height: 30px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}
</style>
