<template>
  <div class="fullwidth tags-scoring pa-3">
    <div class="score-clock-title">
      Tags Score
    </div>
    <div class="flex align-flex-end space-between mb-4 gap-3 m-column">
      <gl-input
        ref="tagName"
        v-model="name"
        class="fullwidth"
        clearable
        :height="40"
        label="tag name"
        name="tag"
        placeholder="Create a unique tag name"
        rules="required|max:75|min:2"
        @blur="handleBlur"
        @clear="clearField"
      />
      <div class="flex gap-3 m-fullwidth align-end">
        <gl-input
          v-model="score"
          v-mask="'###'"
          class="flex-1 width-100"
          :height="40"
          label="tag score"
          min="0"
          name="score"
          rules="required|max_value:100"
          type="number"
          @input="handleScoring"
        />
        <button
          class="gl-button gl-button--dark gl-button--padder action-btn max-w-100"
          data-cy="addTag"
          :disabled="!name || name.length < 2 || name.length > 50 || !String(score)"
          @click="sendNewTag(name, Number(score), metadata)"
        >
          add tag
        </button>
      </div>
    </div>

    <!--    <gl-input-->
    <!--      v-model="metadata"-->
    <!--      :height="40"-->
    <!--      class="mb-4"-->
    <!--      label="Metadata"-->
    <!--      name="metadata"-->
    <!--      clearable-->
    <!--      placeholder="Type metadata"-->
    <!--    />-->

    <div class="mb-3 flex align-center">
      <gl-icon
        class="mr-2 gl-icon--disabled"
        :height="24"
        name="def-report"
        :width="24"
      />
      <div class="grey-text fs-12">
        Tag Score can be set from 0 to 100. The bigger score is the more severe risk.
      </div>
    </div>
    <div class="flex space-between mt-3">
      <div class="bold-600 mb-3 fs-14">
        Current Tags Score
      </div>
      <div class="m-display-none">
        <gl-menu-item
          :disabled="checkedRows.length === 0"
          fullwidth
          icon="delete-action"
          :icon-height="24"
          :icon-width="24"
          label="Delete Selected"
          @click="removeTagOpenModal(checkedRows.map(item => item.name), checkedRows.map(item => item._id))"
        />
      </div>
    </div>
    <div class="flex m-display-block align-flex-end space-between mb-4 mt-2">
      <gl-input
        v-model="search"
        class="mr-4 fullwidth m-mr-3"
        clearable
        data-cy="tagName"
        :height="40"
        label="tag NAME"
        name="type"
        placeholder="Search by a tag name"
        @clear="resetSearch"
      />
      <button
        class="gl-button gl-button--dark gl-button--padder action-btn m-fullwidth m-mt-3"
        data-cy="addTagSearch"
        @click="searching"
      >
        search
      </button>
    </div>
    <div class="m-flex t-display-none display-none mb-4">
      <gl-menu-item
        :disabled="checkedRows.length === 0"
        fullwidth
        icon="delete-action"
        :icon-height="24"
        :icon-width="24"
        label="Delete Selected"
        @click="removeTagOpenModal(checkedRows.map(item => item.name), checkedRows.map(item => item._id))"
      />
    </div>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow main-table-wrap">
      <o-table
        backend-sorting
        checkable
        checkbox-position="left"
        :checked-rows.sync="checkedRows"
        class="table__overflow-auto"
        :class="{ 'o-table-has-pagination': tagData.length && totalPages > 1 }"
        :data="tagData"
        :default-sort="[sortField, sortOrder]"
        :default-sort-direction="defaultSortOrder"
        hoverable
        :is-row-checkable="(row) => row.name !== 'miner' && row.name !== 'coin join participant'"
        :loading="loading"
        :mobile-cards="false"
        sort-icon="chevron-up"
        @check="checkRow"
        @check-all="checkAll"
        @sort="onSort"
      >
        <o-table-column
          v-slot="props"
          field="name"
          label="Tag name"
          sortable
        >
          <div class="flex">
            <gl-mark
              v-if="props.row.score === null"
              class="fit-content mr-2"
              label="New"
            />
            <div>
              {{ props.row.name }}
            </div>
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="score"
          label="Score"
          sortable
          width="100px"
        >
          <div class="score-mark">
            <GlScoreLevelChip
              v-if="!loading"
              :level="props.row.score ? props.row.score.toFixed(0) : 0"
              score
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          align="right"
          field="actions"
          label="Actions"
          width="100px"
        >
          <div class="flex">
            <div style="min-width: 30px">
              <gl-icon
                v-if="props.row.name !== 'miner' && props.row.name !== 'coin join participant'"
                class="pointer mr-2"
                :height="24"
                name="delete-action"
                :width="24"
                @click="removeTagOpenModal(props.row.name, props.row._id)"
              />
            </div>
            <gl-icon
              class="pointer"
              :height="24"
              name="edit-action"
              :width="24"
              @click="editTag(props.row.name, props.row.score, props.row.metadata, props.row._id)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="tagData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <EditTagModal
      v-if="showEditModal"
      :id="editTagId"
      v-model="showEditModal"
      :loading="loading"
      :metadata="editMeta"
      :score="editTagScore"
      :tag="editTagName"
      @close="showEditModal = false"
      @edit="updateTag"
    />
    <confirmUpdateCalcModal
      v-if="showConfirmModal"
      v-model="showConfirmModal"
      :message="message"
      @close="showConfirmModal = false"
      @confirm="confirm"
    />
    <DeleteTagsModal
      v-if="showDelete"
      v-model="showDelete"
      :ids="removedTagsIds"
      :loading="removeTagsLoading"
      :tags="removedTags"
      @close="showDelete = false"
      @delete="removeTag"
    />
  </div>
</template>

<script>
//Vuex
import {mapActions, mapState} from "vuex";
// Mixins
import sidebarTrackingMixin from "@/assets/mixins/sidebarTrackingMixin";
// Components
import vSelect from 'vue-select'
import GlMark from '@/components/gl-mark'
import GlIcon from '@/components/gl-icon'
import GlInput from '@/components/gl-input'
import GlLoader from '@/components/gl-loader'
import EditTagModal from '../modals/EditTagModal'
import GlMenuItem from '@/components/gl-menu-item'
import DeleteTagsModal from '../modals/DeleteTagsModal'
import GlScoreLevelChip from '@/components/gl-score-level-chip'
import confirmUpdateCalcModal from "@/pages/scoring/modals/confirmUpdateCalcModal";
// Utils
import { formatterRecalcApproveMessage } from "@/utils/text-formatter";

export default {
  name: 'TagsScoring',
  components: {
    GlMark,
    GlIcon,
    GlInput,
    vSelect,
    GlLoader,
    GlMenuItem,
    EditTagModal,
    DeleteTagsModal,
    GlScoreLevelChip,
    confirmUpdateCalcModal,
  },
  mixins: [sidebarTrackingMixin],
  data() {
    return {
      loading: false,
      showConfirmModal: false,
      skipCheck: false,
      message: '',
      newTag: '',
      newScore: 0,
      newMeta: 0,
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      total: 1,
      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      name: '',
      metadata: '',
      search: '',
      score: 0,
      tagData: [],
      checkedRows: [],
      removedTags: [],
      removedTagsIds: [],
      editTagName: '',
      editMeta: '',
      editTagScore: '0',
      editTagId: null,
      showEditModal: false,
      showDelete: false,
      removeTagsLoading: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinType']),
  },
  watch: {
    coinType: {
      handler() {
        this.loadData()
      },
      immediate: true
    }
  },
  created() {
    this.perPage = localStorage.getItem('tags-scoring-per-page') || this.perPage
    localStorage.setItem('tags-scoring-per-page', this.perPage)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatterRecalcApproveMessage,
    ...mapActions({
      getTags: 'tagging/getTags',
      deleteTag: 'tagging/deleteTag',
      addTag: 'tagging/addTag',
      editTagData: 'tagging/editTag'
    }),
    confirm() {
      this.showEditModal = false
      this.showDelete = false
      this.skipCheck = true
      if (this.newMeta || this.newScore || this.newTag) {
        this.updateTag(this.newTag, this.newScore, this.newMeta, this.editTagId)
      }

      if (this.removedTagsIds.length > 0) {
        this.removeTag(this.removedTags, this.removedTagsIds)
      }
    },
    handleScoring(score) {
      if (score && Number(score) > 100) {
        this.score = 100
      }
    },
    removeTag(names, ids) {
      this.removeTagsLoading = true
      this.removedTags = names
      this.removedTagsIds = ids

      this.deleteTag({ names, skipCheck: this.skipCheck, ids }).then(({ success, data }) => {
        if (success) {
          this.showEditModal = false
          this.showConfirmModal = false
          this.skipCheck = false
          this.showDelete = false
          this.removedTags = []
          this.removedTagsIds = []
          this.checkedRows = []
          this.removeTagsLoading = false
          this.currentPage = 1
          this.loadData()
        } else {
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
            this.showDelete = false
          } else this.$toasted.global.error({ message: `${data.message}`})
          this.removeTagsLoading = false
        }
      }).finally(() => {
      })
    },
    removeTagOpenModal(names, ids) {
      this.removedTags = Array.isArray(names) ? names : [names]
      this.removedTagsIds = Array.isArray(ids) ? ids : [ids]
      this.showDelete = true
    },
    editTag(name, score, metadata, id) {
      this.editTagName = name
      this.editTagScore = score
      this.editTagId = id
      this.editMeta = metadata
      this.showEditModal = true
    },
    updateTag(name, score, metadata, id) {
      this.message = ''
      this.newTag = ''
      this.newScore = 0
      this.newMeta = 0
      this.loading = true

      this.editTagData({ name: this.editTagName, newName: name, score: Number(score), metadata, skipCheck: this.skipCheck, id }).then(({ success, data }) => {
        if (success) {
          this.editTagName = ''
          this.editTagScore = ''
          this.editTagId = null
          this.showEditModal = false
          this.showConfirmModal = false
          this.skipCheck = false
          this.loadData()
        } else {
          this.newTag = name
          this.newScore = Number(score)
          this.newMeta = metadata
          if (data.showPopup || data?.response?.messageData) {
            this.message = this.formatterRecalcApproveMessage ? this.formatterRecalcApproveMessage(data?.response?.messageData) : data.message
            this.showConfirmModal = true
          } else this.$toasted.global.error({ message: `${data.message}`})
        }
      }).finally(() => {
        this.loading = false
      })
    },
    sendNewTag(name, score, metadata) {
      this.addTag({ name, score, metadata }).then(({ success, data }) => {
        if (success) {
          this.$toasted.global.success({ message: `New tag ${name} with score ${score} was successfully created.`})
          this.metadata = ''
          this.loadData()
        } else {
          this.$toasted.global.error({ message: `${data.message}`})
        }
      })
    },
    resetSearch() {
      this.search = ''
      this.loadData()
    },
    searching() {
      this.currentPage = 1
      this.loadData()
    },
    async loadData() {
      this.loading = true
      const sendParams = this.formattedSendData()
      const { data: { total, tagData } } = await this.getTags(sendParams)
      this.checkUntackedLabels()
      this.tagData = tagData
      this.total = total
      this.totalPages = Math.ceil(this.total / this.perPage)
      this.loading = false
      this.checkedRows = []
    },
    clearField() {
      this.name = ''
      this.$refs.tagName.resetError()
    },
    handleBlur() {
      if (!this.name) this.$refs.tagName.resetError()
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field
      this.loadData()
    },
    checkRow(data) {
      console.log(data)
    },
    checkAll(data) {
      console.log(data)
    },
    formattedSendData() {
      this.perPage = localStorage.getItem('tags-scoring-per-page') || this.perPage

      return {
        sortField: this.sortField || undefined,
        count: this.perPage,
        search: this.search,
        skip: (this.currentPage - 1) * this.perPage,
        sortOrder: this.sortOrder || undefined
      }
    },
    countChange() {
      this.checkedRows = []
      localStorage.setItem('tags-scoring-per-page', this.perPage)
      this.loadData()
    },
    pageChange(event) {
      this.checkedRows = []
      this.currentPage = event
      this.loadData()
    },
  }
}
</script>

<style>
.tags-scoring {
  height: fit-content;
  background: #fff;
}

.score-clock-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

.tags-scoring {
  border-radius: 3px;
}
</style>
